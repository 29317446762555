@import './styles/sharedStyles';

.app {
  background-color: $white;

}

.footerContainer {
  background-color: $innit-wood;
}
