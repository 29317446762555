@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
@import url(https://use.typekit.net/njb2mcb.css);
html {
  overflow: auto; }

body {
  margin: 0;
  font-size: 16px;
  font-family: objektiv-mk3, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.styles_banner__2klBM {
  background-color: #73120d;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 15rem; }
  @media (max-width: 1023px) {
    .styles_banner__2klBM {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: nowrap;
      height: auto;
      padding: 3.5rem 3.5rem 7rem 3.5rem; } }
  .styles_banner__2klBM .styles_bannerText__1mKG2 {
    color: #FFFFFF;
    font-size: 3rem;
    white-space: nowrap;
    padding-right: 1rem; }
    @media (max-width: 1023px) {
      .styles_banner__2klBM .styles_bannerText__1mKG2 {
        font-size: 1.375rem; } }
    @media (max-width: 1500px) {
      .styles_banner__2klBM .styles_bannerText__1mKG2 {
        font-size: 1.75rem; } }
  @media (max-width: 1500px) and (max-width: 1023px) {
    .styles_banner__2klBM .styles_bannerText__1mKG2 {
      font-size: 1rem; } }
    @media (max-width: 1023px) {
      .styles_banner__2klBM .styles_bannerText__1mKG2 {
        font-size: 3.5rem;
        padding: 0;
        margin: 0; } }
  @media (max-width: 1023px) and (max-width: 1023px) {
    .styles_banner__2klBM .styles_bannerText__1mKG2 {
      font-size: 2.625rem; } }
  @media (max-width: 1023px) and (max-width: 599px) {
    .styles_banner__2klBM .styles_bannerText__1mKG2 {
      font-size: 1.5rem; } }
  .styles_banner__2klBM .styles_image__8qZOp {
    display: none;
    width: 14.1875rem;
    height: 8.125rem;
    padding: 0 8rem 1.5625rem 0; }
    @media (max-width: 1500px) {
      .styles_banner__2klBM .styles_image__8qZOp {
        padding: 0 4rem 2.8125rem 0; } }
    .styles_banner__2klBM .styles_image__8qZOp.styles_gaselle__5gjJQ {
      display: block;
      object-fit: contain; }
      @media (max-width: 1023px) {
        .styles_banner__2klBM .styles_image__8qZOp.styles_gaselle__5gjJQ {
          display: block;
          margin-left: -1.875rem; } }
  .styles_banner__2klBM.styles_relativePositioning__26soX {
    position: relative;
    box-sizing: border-box;
    margin-left: calc((100% - 100vw)/2);
    width: 100vw; }

.styles_buttonContainer__3pe3d {
  background-color: #FFFFFF;
  margin: 0.5rem 0;
  width: 19.375rem;
  height: 3.125rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5); }
  @media (max-width: 599px) {
    .styles_buttonContainer__3pe3d {
      width: 15.625rem; } }
  .styles_buttonContainer__3pe3d .styles_iconConatiner__1Cobv {
    padding: 0 1rem; }
  .styles_buttonContainer__3pe3d .styles_textConatiner__12CbO {
    padding: 0 2rem;
    font-size: 1.125rem; }

.styles_contactInfoWrapper__2xARS {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 1.5rem; }
  @media (max-width: 1199px) {
    .styles_contactInfoWrapper__2xARS {
      padding-left: 2rem;
      padding-right: 2rem; } }
  @media (max-width: 1023px) {
    .styles_contactInfoWrapper__2xARS {
      font-size: 1.25rem; } }
  @media (max-width: 599px) {
    .styles_contactInfoWrapper__2xARS {
      font-size: 1rem; } }
  .styles_contactInfoWrapper__2xARS .styles_title__34gWJ {
    font-family: objektiv-mk3, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.5rem;
    margin: 0 0 1rem 0; }
    @media (max-width: 1023px) {
      .styles_contactInfoWrapper__2xARS .styles_title__34gWJ {
        font-size: 1.25rem; } }
    @media (max-width: 599px) {
      .styles_contactInfoWrapper__2xARS .styles_title__34gWJ {
        font-size: 1rem; } }
  .styles_contactInfoWrapper__2xARS .styles_contactInfo__YRjRu {
    font-family: objektiv-mk3, sans-serif;
    font-weight: 300;
    font-style: normal;
    margin: 0;
    color: #000000; }
    @media (max-width: 599px) {
      .styles_contactInfoWrapper__2xARS .styles_contactInfo__YRjRu .styles_filler__LZHAt {
        display: none; }
      .styles_contactInfoWrapper__2xARS .styles_contactInfo__YRjRu > * {
        display: block; } }
    .styles_contactInfoWrapper__2xARS .styles_contactInfo__YRjRu .styles_mail__2-kzb {
      color: #000000; }
    .styles_contactInfoWrapper__2xARS .styles_contactInfo__YRjRu .styles_phone__LH9HK {
      color: #000000;
      text-decoration: none; }

.styles_icon__3rFQA {
  height: 2rem;
  width: 2rem;
  fill: red; }

.styles_cSContainer__3Z4oZ {
  display: inline-flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  background-color: #d7e3f1; }
  @media (max-width: 599px) {
    .styles_cSContainer__3Z4oZ {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      padding-bottom: 4rem; } }
  .styles_cSContainer__3Z4oZ .styles_cSImage__Mg1iX {
    height: 31.25rem;
    width: 31.25rem;
    object-fit: cover; }
    @media (max-width: 599px) {
      .styles_cSContainer__3Z4oZ .styles_cSImage__Mg1iX {
        width: 24.375rem;
        height: 16.25rem; } }
  .styles_cSContainer__3Z4oZ .styles_cSBody__UAEhK {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap; }
    .styles_cSContainer__3Z4oZ .styles_cSBody__UAEhK .styles_cSTitle__3Ihfe {
      font-family: objektiv-mk3, sans-serif;
      font-weight: 500;
      font-style: normal;
      padding: 1rem;
      text-align: start; }
    .styles_cSContainer__3Z4oZ .styles_cSBody__UAEhK .styles_cSButtons__dpSxP {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap; }
  .styles_cSContainer__3Z4oZ .styles_cSFooter__2hVcN {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap; }
    .styles_cSContainer__3Z4oZ .styles_cSFooter__2hVcN .styles_cSFooterText__2Ur8v {
      font-family: objektiv-mk3, sans-serif;
      font-weight: 300;
      font-style: normal;
      padding: 1rem 0; }
    .styles_cSContainer__3Z4oZ .styles_cSFooter__2hVcN a {
      text-decoration: none; }
    .styles_cSContainer__3Z4oZ .styles_cSFooter__2hVcN .styles_callUs__19529 {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap; }
      .styles_cSContainer__3Z4oZ .styles_cSFooter__2hVcN .styles_callUs__19529 .styles_callText__Aoklk {
        color: #000000;
        font-size: 1.5625rem;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        padding-left: 0.5rem; }

.styles_imgsContainer__2L8IN {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr); }
  .styles_imgsContainer__2L8IN .styles_imgWrapper__2FLGO {
    position: relative; }
    .styles_imgsContainer__2L8IN .styles_imgWrapper__2FLGO .styles_img__1qcyY {
      max-width: 25rem;
      object-fit: contain; }
      @media (max-width: 599px) {
        .styles_imgsContainer__2L8IN .styles_imgWrapper__2FLGO .styles_img__1qcyY {
          height: auto;
          width: 100%; } }
    .styles_imgsContainer__2L8IN .styles_imgWrapper__2FLGO .styles_imgCaption__1lhND {
      font-family: objektiv-mk3, sans-serif;
      font-weight: 200;
      font-style: normal;
      font-size: 0.875rem;
      position: absolute;
      left: 1rem;
      bottom: 1rem;
      box-sizing: border-box;
      padding: 0.625rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 1;
      -webkit-transition: 50%, 50%;
      transition: 50%, 50%;
      background-color: #172727;
      -webkit-transition: opacity 1s ease;
      transition: opacity 1s ease;
      color: white; }
      @media (max-width: 1023px) {
        .styles_imgsContainer__2L8IN .styles_imgWrapper__2FLGO .styles_imgCaption__1lhND {
          font-size: 0.75rem; } }
      @media (min-width: 1200px) {
        .styles_imgsContainer__2L8IN .styles_imgWrapper__2FLGO .styles_imgCaption__1lhND {
          opacity: 0; } }
    @media (min-width: 1200px) {
      .styles_imgsContainer__2L8IN .styles_imgWrapper__2FLGO:hover .styles_imgCaption__1lhND {
        opacity: 1; } }
  .styles_imgsContainer__2L8IN .styles_firstImg__1alo1 {
    grid-column: 2 / 7;
    grid-row: 1 / 6; }
    .styles_imgsContainer__2L8IN .styles_firstImg__1alo1.styles_isReversed__XD4eJ {
      grid-column: 1 / 6;
      grid-row: 1 / 6; }
  .styles_imgsContainer__2L8IN .styles_secondImg__21Lhi {
    grid-column: 1 / 6;
    grid-row: 2 / 7; }
    .styles_imgsContainer__2L8IN .styles_secondImg__21Lhi.styles_isReversed__XD4eJ {
      grid-column: 2 / 7;
      grid-row: 2 / 7; }

.styles_dropDownConatiner__-0JVu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap; }
  .styles_dropDownConatiner__-0JVu .styles_dropDownButton__zA14S {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    margin: 0.5rem 0;
    padding: 0;
    min-width: 14.375rem;
    background-color: transparent;
    text-decoration: underline;
    font-family: objektiv-mk3, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.5rem;
    text-align: start;
    border-style: none;
    outline: none; }
    @media (max-width: 1023px) {
      .styles_dropDownConatiner__-0JVu .styles_dropDownButton__zA14S {
        font-size: 1.25rem; } }
    @media (max-width: 599px) {
      .styles_dropDownConatiner__-0JVu .styles_dropDownButton__zA14S {
        font-size: 1rem; } }
    .styles_dropDownConatiner__-0JVu .styles_dropDownButton__zA14S .styles_chevronWrapper__2krOE {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap; }
  .styles_dropDownConatiner__-0JVu .styles_listContainer__1gJK2 {
    background-color: #d7e3f1;
    min-width: 14.375rem;
    margin: 0.5rem 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap; }
    .styles_dropDownConatiner__-0JVu .styles_listContainer__1gJK2 .styles_listItem__tzudF {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      background-color: transparent;
      padding: 0.5rem 1rem;
      width: inherit;
      border-style: none;
      outline: none;
      text-align: start;
      font-family: objektiv-mk3, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 1.5rem; }
      @media (max-width: 1023px) {
        .styles_dropDownConatiner__-0JVu .styles_listContainer__1gJK2 .styles_listItem__tzudF {
          font-size: 1.25rem; } }
      @media (max-width: 599px) {
        .styles_dropDownConatiner__-0JVu .styles_listContainer__1gJK2 .styles_listItem__tzudF {
          font-size: 1rem; } }

.styles_employeeContainer__bkCVK {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 11.25rem;
  padding-right: 3.5rem;
  padding-bottom: 1rem; }
  @media (max-width: 1199px) {
    .styles_employeeContainer__bkCVK {
      width: 9.375rem; } }
  @media (max-width: 599px) {
    .styles_employeeContainer__bkCVK {
      padding: 0.5rem; } }
  .styles_employeeContainer__bkCVK .styles_employeeImage__3kUkM {
    width: 11.25rem;
    height: 11.25rem;
    object-fit: contain; }
    @media (max-width: 1199px) {
      .styles_employeeContainer__bkCVK .styles_employeeImage__3kUkM {
        width: 9.375rem;
        height: 9.375rem; } }
  .styles_employeeContainer__bkCVK .styles_employeeText__2x0Lr {
    text-align: start;
    line-height: 1.5rem;
    font-family: objektiv-mk3, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 0.875rem; }
    @media (max-width: 1023px) {
      .styles_employeeContainer__bkCVK .styles_employeeText__2x0Lr {
        font-size: 0.75rem; } }
  .styles_employeeContainer__bkCVK .styles_employeeMail__1RFqU {
    font-family: objektiv-mk3, sans-serif;
    font-weight: 300;
    font-style: normal;
    padding-top: 0.5rem;
    color: black;
    text-decoration: none; }
    .styles_employeeContainer__bkCVK .styles_employeeMail__1RFqU:hover {
      color: #ff000a; }

.styles_factsContainer__2pWzp {
  padding-top: 6rem; }
  @media (max-width: 599px) {
    .styles_factsContainer__2pWzp {
      padding-top: 3rem; } }

.styles_container__wjy5E {
  background-color: #d7e3f1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  align-items: center;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  width: 100%; }
  @media (max-width: 1023px) {
    .styles_container__wjy5E {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: nowrap;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-top: 2rem; } }
  @media (max-width: 599px) {
    .styles_container__wjy5E {
      padding-top: 1rem; } }
  .styles_container__wjy5E .styles_imgWrapper__p-XUq {
    height: 18rem;
    flex: 1 0 50%;
    max-width: 50%; }
    @media (max-width: 1023px) {
      .styles_container__wjy5E .styles_imgWrapper__p-XUq {
        max-width: 100%;
        margin: 0 auto; } }
    .styles_container__wjy5E .styles_imgWrapper__p-XUq .styles_img__XwGpc {
      height: 100%;
      width: 100%;
      object-fit: contain; }
  .styles_container__wjy5E .styles_textBlock__9AWkI {
    flex: 1 0 25%;
    padding-left: 4rem;
    padding-right: 4rem; }
    @media (max-width: 1023px) {
      .styles_container__wjy5E .styles_textBlock__9AWkI {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 2rem;
        flex: 1 0 40%; } }
    @media (max-width: 599px) {
      .styles_container__wjy5E .styles_textBlock__9AWkI {
        padding-left: 2rem;
        padding-right: 2rem; } }
    .styles_container__wjy5E .styles_textBlock__9AWkI .styles_title__3leIo {
      margin-top: 0;
      margin-bottom: 2rem;
      font-family: objektiv-mk3, sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 1.5rem; }
      @media (max-width: 1023px) {
        .styles_container__wjy5E .styles_textBlock__9AWkI .styles_title__3leIo {
          font-size: 1.25rem; } }
      @media (max-width: 599px) {
        .styles_container__wjy5E .styles_textBlock__9AWkI .styles_title__3leIo {
          font-size: 1rem; } }
      @media (max-width: 599px) {
        .styles_container__wjy5E .styles_textBlock__9AWkI .styles_title__3leIo {
          margin-bottom: 0;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem; } }
    .styles_container__wjy5E .styles_textBlock__9AWkI .styles_text__3RWYn {
      font-family: objektiv-mk3, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 1.5rem; }
      @media (max-width: 1023px) {
        .styles_container__wjy5E .styles_textBlock__9AWkI .styles_text__3RWYn {
          font-size: 1.25rem; } }
      @media (max-width: 599px) {
        .styles_container__wjy5E .styles_textBlock__9AWkI .styles_text__3RWYn {
          font-size: 1rem; } }
      @media (max-width: 599px) {
        .styles_container__wjy5E .styles_textBlock__9AWkI .styles_text__3RWYn {
          margin: 0;
          margin-bottom: 1rem; } }

.styles_footer__2jx7K {
  color: #FFFFFF;
  background-color: #172727;
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin: 0 auto;
  max-width: 1200px;
  font-family: objektiv-mk3, sans-serif;
  font-weight: 500;
  font-style: normal; }
  @media (max-width: 599px) {
    .styles_footer__2jx7K {
      padding-left: 2rem;
      padding-right: 2rem; } }
  .styles_footer__2jx7K .styles_socials__8RRKO {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap; }
    .styles_footer__2jx7K .styles_socials__8RRKO .styles_socialIcon__HODS3 {
      fill: #FFFFFF;
      padding-right: 2rem;
      height: 64px;
      width: 64px; }
  .styles_footer__2jx7K .styles_contactUs__3i0R0 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap; }
    @media (max-width: 1023px) {
      .styles_footer__2jx7K .styles_contactUs__3i0R0 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap; } }
    .styles_footer__2jx7K .styles_contactUs__3i0R0 .styles_adress__1dE4u {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: nowrap;
      text-align: start;
      padding: 2rem 2rem 0 0; }
    .styles_footer__2jx7K .styles_contactUs__3i0R0 .styles_footerText__3Trt5 {
      line-height: 1.5rem; }
    .styles_footer__2jx7K .styles_contactUs__3i0R0 .styles_privacyStatement__3ptCZ {
      align-self: flex-end;
      color: #FFFFFF;
      margin-left: auto; }
      @media (max-width: 78.75rem) {
        .styles_footer__2jx7K .styles_contactUs__3i0R0 .styles_privacyStatement__3ptCZ {
          align-self: flex-start;
          margin: 0;
          padding: 2rem 2rem 0 0; } }

.styles_header__2dQfB {
  display: grid;
  grid-template-columns: minmax(50%, 1fr) minmax(12.5rem, 31.25rem);
  grid-template-rows: minmax(12.5rem, 18.75rem) minmax(12.5rem, 31.25rem);
  padding-left: 4rem;
  background-color: #172727;
  color: #FFFFFF;
  overflow: hidden; }
  @media (max-width: 599px) {
    .styles_header__2dQfB {
      grid-template-rows: minmax(4rem, 4.5rem) 12.5rem;
      padding-left: 2rem; } }
  .styles_header__2dQfB .styles_title__3N7E- {
    grid-column-start: 1;
    grid-column-end: 1;
    justify-self: start;
    align-self: end; }
    @media (max-width: 599px) {
      .styles_header__2dQfB .styles_title__3N7E- {
        font-size: 0.75rem; } }
  .styles_header__2dQfB .styles_descriptionContainer__1aaMg {
    grid-column-start: 1;
    grid-column-end: 1; }
    .styles_header__2dQfB .styles_descriptionContainer__1aaMg .styles_description__1UOQs {
      text-align: start;
      font-size: 3.5rem;
      margin: 0; }
      @media (max-width: 599px) {
        .styles_header__2dQfB .styles_descriptionContainer__1aaMg .styles_description__1UOQs {
          font-size: 1.125rem; } }
  .styles_header__2dQfB .styles_imgContainer__2gGjf .styles_img__305FV {
    width: auto;
    height: auto; }

.styles_linkWrapper__M0HZe {
  align-self: flex-end; }
  .styles_linkWrapper__M0HZe .styles_linkText__2qfq8 {
    justify-content: end;
    display: grid;
    grid-template-columns: fit-content(31.25rem);
    grid-template-rows: 1fr;
    width: 100%;
    font-family: objektiv-mk3, sans-serif;
    font-weight: 300;
    font-style: normal;
    text-decoration: none;
    font-size: 1.5rem;
    padding: 1rem 0; }
    @media (max-width: 1023px) {
      .styles_linkWrapper__M0HZe .styles_linkText__2qfq8 {
        font-size: 1.25rem; } }
    @media (max-width: 599px) {
      .styles_linkWrapper__M0HZe .styles_linkText__2qfq8 {
        font-size: 1rem; } }
    .styles_linkWrapper__M0HZe .styles_linkText__2qfq8 .styles_text__3CZ3m {
      padding: 0 0.25rem;
      grid-column: 1 / 1;
      grid-row: 1 / 1;
      z-index: 10; }
    .styles_linkWrapper__M0HZe .styles_linkText__2qfq8 .styles_underline__24wmB {
      align-self: end;
      grid-column: 1 / 1;
      grid-row: 1 / 1;
      background-color: #ff000a;
      height: 1px;
      z-index: 9; }

.styles_container__20GFp {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr); }
  @media (max-width: 599px) {
    .styles_container__20GFp {
      grid-template-rows: repeat(2, 1fr); } }
  .styles_container__20GFp .styles_imgWrapper__2NPbP {
    grid-column: 1 / 4;
    grid-row: 1 / 3;
    max-width: 37.5rem;
    height: 25rem;
    overflow: hidden; }
    @media (max-width: 1023px) {
      .styles_container__20GFp .styles_imgWrapper__2NPbP {
        grid-column: 1 / 6; } }
    .styles_container__20GFp .styles_imgWrapper__2NPbP.styles_isReversed__2RXpZ {
      grid-column: 3 / 7;
      justify-self: end; }
      @media (max-width: 1023px) {
        .styles_container__20GFp .styles_imgWrapper__2NPbP.styles_isReversed__2RXpZ {
          grid-column: 2 / 7; } }
    .styles_container__20GFp .styles_imgWrapper__2NPbP .styles_img__3S9XP {
      width: 100%;
      height: auto;
      object-fit: contain; }
  .styles_container__20GFp .styles_textBlock__24i-Y {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    grid-column: 2 / 5;
    grid-row: 2 / 4; }
    @media (max-width: 1023px) {
      .styles_container__20GFp .styles_textBlock__24i-Y {
        padding-left: 2rem;
        padding-right: 2rem;
        grid-column: 2 / 7; } }
    .styles_container__20GFp .styles_textBlock__24i-Y.styles_isReversed__2RXpZ {
      grid-column: 3 / 6; }
      @media (max-width: 1023px) {
        .styles_container__20GFp .styles_textBlock__24i-Y.styles_isReversed__2RXpZ {
          grid-column: 1 / 6; } }
    .styles_container__20GFp .styles_textBlock__24i-Y.styles_innit-blood__1sMnT {
      background-color: #73120d;
      color: #FFFFFF; }
    .styles_container__20GFp .styles_textBlock__24i-Y.styles_innit-ice__3lVN_ {
      background-color: #d7e3f1;
      color: #000000; }
    .styles_container__20GFp .styles_textBlock__24i-Y .styles_title__3Q7Ch {
      margin: 0 0 0.25rem 0;
      font-size: 1.5rem;
      font-family: objektiv-mk3, sans-serif;
      font-weight: 500;
      font-style: normal; }
      @media (max-width: 1023px) {
        .styles_container__20GFp .styles_textBlock__24i-Y .styles_title__3Q7Ch {
          font-size: 1.25rem; } }
      @media (max-width: 599px) {
        .styles_container__20GFp .styles_textBlock__24i-Y .styles_title__3Q7Ch {
          font-size: 1rem; } }
    .styles_container__20GFp .styles_textBlock__24i-Y .styles_text__2h2cG {
      margin: 0;
      font-size: 1.5rem;
      font-family: objektiv-mk3, sans-serif;
      font-weight: 300;
      font-style: normal; }
      @media (max-width: 1023px) {
        .styles_container__20GFp .styles_textBlock__24i-Y .styles_text__2h2cG {
          font-size: 1.25rem; } }
      @media (max-width: 599px) {
        .styles_container__20GFp .styles_textBlock__24i-Y .styles_text__2h2cG {
          font-size: 1rem; } }

.styles_button__z9BvM {
  background-color: #172727;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 2.5rem;
  height: 2.5rem;
  border-color: transparent;
  border-radius: 100%;
  padding-top: 0.25rem;
  outline: none;
  z-index: 5; }
  .styles_button__z9BvM svg {
    padding-left: 0.0625rem; }

.styles_link__3I3kF {
  text-decoration: none; }
  .styles_link__3I3kF .styles_linkWrapper__2mWUy {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap; }
    .styles_link__3I3kF .styles_linkWrapper__2mWUy .styles_linkText__2S3CS {
      color: #FFFFFF;
      font-size: 2.625rem; }
      @media (max-width: 1023px) {
        .styles_link__3I3kF .styles_linkWrapper__2mWUy .styles_linkText__2S3CS {
          font-size: 1.75rem; } }
      @media (max-width: 599px) {
        .styles_link__3I3kF .styles_linkWrapper__2mWUy .styles_linkText__2S3CS {
          font-size: 1.25rem; } }
      @media (min-width: 1024px) {
        .styles_link__3I3kF .styles_linkWrapper__2mWUy .styles_linkText__2S3CS {
          font-size: 1.75rem; } }
  @media (min-width: 1024px) and (max-width: 1023px) {
    .styles_link__3I3kF .styles_linkWrapper__2mWUy .styles_linkText__2S3CS {
      font-size: 1rem; } }
      @media (min-width: 1200px) {
        .styles_link__3I3kF .styles_linkWrapper__2mWUy .styles_linkText__2S3CS {
          font-size: 2.625rem; } }
  @media (min-width: 1200px) and (max-width: 1023px) {
    .styles_link__3I3kF .styles_linkWrapper__2mWUy .styles_linkText__2S3CS {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) and (max-width: 599px) {
    .styles_link__3I3kF .styles_linkWrapper__2mWUy .styles_linkText__2S3CS {
      font-size: 1.25rem; } }
    .styles_link__3I3kF .styles_linkWrapper__2mWUy .styles_arrow__3Z5V5 {
      fill: #FFFFFF;
      width: 3.125rem;
      height: 3.125rem;
      padding: 1rem 0 0 1rem; }
      @media (max-width: 1023px) {
        .styles_link__3I3kF .styles_linkWrapper__2mWUy .styles_arrow__3Z5V5 {
          width: 2.5rem;
          height: 2.5rem;
          padding-top: 0.5rem; } }

.styles_slidingMenu__KGYsy {
  background-color: #172727;
  position: fixed;
  z-index: 1;
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap; }
  @media (max-width: 599px) {
    .styles_slidingMenu__KGYsy {
      min-height: 100vh;
      align-items: center; } }
  .styles_slidingMenu__KGYsy .styles_logoWrapper__fhge2 {
    width: 100%;
    position: absolute;
    opacity: 0;
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap; }
    .styles_slidingMenu__KGYsy .styles_logoWrapper__fhge2 .styles_logoContainer__x5ys4 {
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;
      margin: 0 auto;
      max-width: 1200px;
      z-index: 1; }
      .styles_slidingMenu__KGYsy .styles_logoWrapper__fhge2 .styles_logoContainer__x5ys4 .styles_logo__2-yf4 {
        padding-right: 60px;
        fill: #FFFFFF;
        height: 6.25rem;
        width: 6.25rem;
        z-index: inherit; }
        @media (max-width: 599px) {
          .styles_slidingMenu__KGYsy .styles_logoWrapper__fhge2 .styles_logoContainer__x5ys4 .styles_logo__2-yf4 {
            height: 4.5rem;
            width: 4.5rem; } }
  .styles_slidingMenu__KGYsy .styles_linksWrapper__3_w0f {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    margin: 0 auto;
    max-width: 1200px;
    padding-left: 8rem;
    padding-right: 8rem;
    flex: 1 1;
    width: 100%;
    height: 100%;
    padding-top: 6.25rem;
    padding-bottom: 6.25rem; }
    @media (max-width: 599px) {
      .styles_slidingMenu__KGYsy .styles_linksWrapper__3_w0f {
        padding-left: 2rem;
        padding-right: 2rem; } }
    @media (max-width: 599px) {
      .styles_slidingMenu__KGYsy .styles_linksWrapper__3_w0f {
        padding-top: 0;
        padding-bottom: 0; } }

.styles_menuContainer__1VC3c {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  position: absolute;
  overflow: auto; }
  .styles_menuContainer__1VC3c .styles_menuWrapper__tNZA_ {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    margin: 0 auto;
    max-width: 1200px; }
    .styles_menuContainer__1VC3c .styles_menuWrapper__tNZA_ .styles_toggleContainer__1MHnE {
      position: fixed;
      align-self: center;
      z-index: 5;
      right: inherit;
      padding-right: 1rem;
      padding-bottom: 0.25rem; }
    .styles_menuContainer__1VC3c .styles_menuWrapper__tNZA_ .styles_logoContainer__3gj31 {
      margin-right: 4rem;
      z-index: 1; }
      .styles_menuContainer__1VC3c .styles_menuWrapper__tNZA_ .styles_logoContainer__3gj31 .styles_logo__2ZAxw {
        fill: #FFFFFF;
        height: 6.25rem;
        width: 6.25rem;
        z-index: inherit; }
        @media (max-width: 599px) {
          .styles_menuContainer__1VC3c .styles_menuWrapper__tNZA_ .styles_logoContainer__3gj31 .styles_logo__2ZAxw {
            height: 4.5rem;
            width: 4.5rem; } }

.styles_newsTicker__1WlOl {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: solid;
  border-width: 0.0625rem; }
  .styles_newsTicker__1WlOl .styles_tickerWrapper__30IKs {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    padding-left: 100%; }
    @media (max-width: 599px) {
      .styles_newsTicker__1WlOl .styles_tickerWrapper__30IKs {
        font-size: 0.875rem; } }
  @media (max-width: 599px) and (max-width: 1023px) {
    .styles_newsTicker__1WlOl .styles_tickerWrapper__30IKs {
      font-size: 0.75rem; } }
    .styles_newsTicker__1WlOl .styles_tickerWrapper__30IKs .styles_news__1MHbm {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      white-space: nowrap;
      padding-right: 3rem; }
      .styles_newsTicker__1WlOl .styles_tickerWrapper__30IKs .styles_news__1MHbm .styles_arrow__NoukJ {
        width: 1.25rem;
        height: 1.25rem;
        fill: #ff000a;
        padding-right: 0.5rem; }

.styles_logoWrapper__2flJz {
  padding: 0.5rem 2.5rem 2rem 0;
  max-height: 3.125rem;
  max-width: 10vh; }
  @media (max-width: 1023px) {
    .styles_logoWrapper__2flJz {
      padding-right: 1rem;
      max-width: 10vh; } }
  .styles_logoWrapper__2flJz .styles_logo__2Kfrz {
    object-fit: cover;
    height: auto;
    width: 100%; }

.styles_sampleContainer__2cw18 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1; }
  .styles_sampleContainer__2cw18.styles_isReverese__CtsFZ {
    flex-direction: row-reverse; }
  .styles_sampleContainer__2cw18 .styles_sampleImgContainer__3-lYG {
    width: 37.5rem;
    height: 25rem;
    position: relative; }
    @media (min-width: 1200px) {
      .styles_sampleContainer__2cw18 .styles_sampleImgContainer__3-lYG {
        flex: 1 1; } }
    @media (max-width: 599px) {
      .styles_sampleContainer__2cw18 .styles_sampleImgContainer__3-lYG {
        width: 20rem;
        height: 13.3125rem; } }
    .styles_sampleContainer__2cw18 .styles_sampleImgContainer__3-lYG .styles_sampleImg__6iOPW {
      object-fit: cover;
      width: inherit;
      height: inherit; }
    .styles_sampleContainer__2cw18 .styles_sampleImgContainer__3-lYG .styles_imgCaption__1qgXh {
      font-family: objektiv-mk3, sans-serif;
      font-weight: 200;
      font-style: normal;
      font-size: 0.875rem;
      position: absolute;
      left: 1rem;
      bottom: 1rem;
      box-sizing: border-box;
      padding: 0.625rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 1;
      background-color: #172727;
      -webkit-transition: opacity 1s ease;
      transition: opacity 1s ease;
      color: white; }
      @media (max-width: 1023px) {
        .styles_sampleContainer__2cw18 .styles_sampleImgContainer__3-lYG .styles_imgCaption__1qgXh {
          font-size: 0.75rem; } }
      @media (min-width: 1200px) {
        .styles_sampleContainer__2cw18 .styles_sampleImgContainer__3-lYG .styles_imgCaption__1qgXh {
          opacity: 0; } }
    @media (min-width: 1200px) {
      .styles_sampleContainer__2cw18 .styles_sampleImgContainer__3-lYG:hover .styles_imgCaption__1qgXh {
        opacity: 1; } }
  .styles_sampleContainer__2cw18 .styles_sampleTextContainer__1SiJn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    padding-top: 3rem;
    padding-bottom: 3rem;
    flex: 1 1; }
    @media (min-width: 1024px) {
      .styles_sampleContainer__2cw18 .styles_sampleTextContainer__1SiJn {
        padding-left: 4.5rem;
        padding-right: 4.5rem; } }
    .styles_sampleContainer__2cw18 .styles_sampleTextContainer__1SiJn .styles_sampleTitle__222Jd {
      font-family: objektiv-mk3, sans-serif;
      font-weight: 500;
      font-style: normal;
      margin: 0;
      font-size: 1.5rem; }
      @media (max-width: 1023px) {
        .styles_sampleContainer__2cw18 .styles_sampleTextContainer__1SiJn .styles_sampleTitle__222Jd {
          font-size: 1.25rem; } }
      @media (max-width: 599px) {
        .styles_sampleContainer__2cw18 .styles_sampleTextContainer__1SiJn .styles_sampleTitle__222Jd {
          font-size: 1rem; } }
    .styles_sampleContainer__2cw18 .styles_sampleTextContainer__1SiJn .styles_sampleDesc__1UMCV {
      font-family: objektiv-mk3, sans-serif;
      font-weight: 300;
      font-style: normal;
      font-size: 1.5rem; }
      @media (max-width: 1023px) {
        .styles_sampleContainer__2cw18 .styles_sampleTextContainer__1SiJn .styles_sampleDesc__1UMCV {
          font-size: 1.25rem; } }
      @media (max-width: 599px) {
        .styles_sampleContainer__2cw18 .styles_sampleTextContainer__1SiJn .styles_sampleDesc__1UMCV {
          font-size: 1rem; } }

.styles_container__MmchU {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  padding-top: 4.5rem;
  width: 100%;
  position: relative; }
  @media (max-width: 599px) {
    .styles_container__MmchU {
      padding-top: 1rem; } }
  .styles_container__MmchU .styles_imgWrapperFirst__2BKVE, .styles_container__MmchU .styles_imgWrapperSecond__DbJVz, .styles_container__MmchU .styles_imgWrapperThird__TKaSC {
    height: 37.5rem;
    width: 100%;
    position: relative; }
    .styles_container__MmchU .styles_imgWrapperFirst__2BKVE .styles_button__1rPOU, .styles_container__MmchU .styles_imgWrapperSecond__DbJVz .styles_button__1rPOU, .styles_container__MmchU .styles_imgWrapperThird__TKaSC .styles_button__1rPOU {
      display: contents; }
    .styles_container__MmchU .styles_imgWrapperFirst__2BKVE .styles_img__2YLvk, .styles_container__MmchU .styles_imgWrapperSecond__DbJVz .styles_img__2YLvk, .styles_container__MmchU .styles_imgWrapperThird__TKaSC .styles_img__2YLvk {
      position: absolute;
      height: 70%;
      width: 100%;
      object-fit: contain;
      bottom: 50px; }
    .styles_container__MmchU .styles_imgWrapperFirst__2BKVE .styles_title__O2kz8, .styles_container__MmchU .styles_imgWrapperSecond__DbJVz .styles_title__O2kz8, .styles_container__MmchU .styles_imgWrapperThird__TKaSC .styles_title__O2kz8 {
      text-align: center;
      padding-top: 2rem; }
  .styles_container__MmchU .styles_imgWrapperFirst__2BKVE {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, white), color-stop(50%, #172727));
    background: linear-gradient(white 50%, #172727 50%); }
  .styles_container__MmchU .styles_imgWrapperSecond__DbJVz {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #d7e3f1), color-stop(50%, white));
    background: linear-gradient(#d7e3f1 50%, white 50%); }
  .styles_container__MmchU .styles_imgWrapperThird__TKaSC {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, white), color-stop(50%, #73120d));
    background: linear-gradient(white 50%, #73120d 50%); }
  .styles_container__MmchU .styles_dotContainer__1MrRq {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: absolute;
    bottom: 17vh;
    left: 0;
    right: 0; }
  .styles_container__MmchU .styles_arrowContainer__2C9HU {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0; }
    .styles_container__MmchU .styles_arrowContainer__2C9HU .styles_arrow__13ytW {
      width: 12vh;
      height: 12vh;
      fill: #ff000a;
      -webkit-transform: rotateZ(270deg);
              transform: rotateZ(270deg); }

.styles_container__3qMN4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding-bottom: 4rem;
  max-width: 62.5rem;
  font-size: 1.75rem;
  font-family: objektiv-mk3, sans-serif;
  font-weight: 300;
  font-style: normal; }
  @media (max-width: 1023px) {
    .styles_container__3qMN4 {
      font-size: 1rem; } }
  .styles_container__3qMN4 .styles_title__DSOWe {
    font-size: 2.625rem;
    font-family: objektiv-mk3, sans-serif;
    font-weight: 500;
    font-style: normal; }
    @media (max-width: 1023px) {
      .styles_container__3qMN4 .styles_title__DSOWe {
        font-size: 1.75rem; } }
    @media (max-width: 599px) {
      .styles_container__3qMN4 .styles_title__DSOWe {
        font-size: 1.25rem; } }
  .styles_container__3qMN4 .styles_arrowStyle__1EInH {
    height: 12.5rem;
    width: 12.5rem;
    -webkit-transform: rotateZ(-45deg);
            transform: rotateZ(-45deg); }
    @media (max-width: 599px) {
      .styles_container__3qMN4 .styles_arrowStyle__1EInH {
        height: 7.5rem;
        width: 7.5rem; } }

.styles_container__2Wtxh {
  width: 100%;
  padding-top: 4.5rem;
  position: relative; }
  @media (max-width: 599px) {
    .styles_container__2Wtxh {
      padding-top: 1rem; } }
  .styles_container__2Wtxh .styles_img__2gnbB {
    height: 100%;
    width: 100%;
    object-fit: contain; }
  .styles_container__2Wtxh .styles_captionContainer__2GuHH {
    font-family: objektiv-mk3, sans-serif;
    font-weight: 200;
    font-style: normal;
    font-size: 0.875rem;
    position: absolute;
    box-sizing: border-box;
    padding: 0.625rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #172727;
    left: 1rem;
    bottom: 1rem;
    opacity: 0; }
    @media (max-width: 1023px) {
      .styles_container__2Wtxh .styles_captionContainer__2GuHH {
        font-size: 0.75rem; } }
    @media (max-width: 1023px) {
      .styles_container__2Wtxh .styles_captionContainer__2GuHH {
        opacity: 1; } }
    .styles_container__2Wtxh .styles_captionContainer__2GuHH .styles_figurecaption__2SS6l {
      color: white;
      margin: 0; }

.styles_container__2Wtxh:hover .styles_captionContainer__2GuHH {
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
  opacity: 1; }

.styles_container__lWIy2 {
  padding-top: 4.5rem;
  padding-left: 4rem;
  padding-right: 4rem; }
  @media (max-width: 1023px) {
    .styles_container__lWIy2 {
      padding-top: 2rem;
      padding-left: 0;
      padding-right: 0; } }
  .styles_container__lWIy2 .styles_title__2plt1 {
    font-family: objektiv-mk3, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 1.5rem;
    margin: 0; }
    @media (max-width: 1023px) {
      .styles_container__lWIy2 .styles_title__2plt1 {
        font-size: 1.25rem; } }
    @media (max-width: 599px) {
      .styles_container__lWIy2 .styles_title__2plt1 {
        font-size: 1rem; } }

.styles_textWithLinkContainer__1v-H5 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap; }
  .styles_textWithLinkContainer__1v-H5 .styles_title__1Urvj {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
    font-family: objektiv-mk3, sans-serif;
    font-weight: 500;
    font-style: normal; }
    @media (max-width: 1023px) {
      .styles_textWithLinkContainer__1v-H5 .styles_title__1Urvj {
        font-size: 1.25rem; } }
    @media (max-width: 599px) {
      .styles_textWithLinkContainer__1v-H5 .styles_title__1Urvj {
        font-size: 1rem; } }
  .styles_textWithLinkContainer__1v-H5 .styles_text__c04_1 {
    font-size: 1.5rem;
    font-family: objektiv-mk3, sans-serif;
    font-weight: 300;
    font-style: normal;
    margin: 0;
    align-self: flex-start; }
    @media (max-width: 1023px) {
      .styles_textWithLinkContainer__1v-H5 .styles_text__c04_1 {
        font-size: 1.25rem; } }
    @media (max-width: 599px) {
      .styles_textWithLinkContainer__1v-H5 .styles_text__c04_1 {
        font-size: 1rem; } }
    .styles_textWithLinkContainer__1v-H5 .styles_text__c04_1 a {
      color: #000000; }
    .styles_textWithLinkContainer__1v-H5 .styles_text__c04_1 :first-child {
      margin-top: 0; }
    .styles_textWithLinkContainer__1v-H5 .styles_text__c04_1 :last-child {
      margin-bottom: 0; }

.styles_container__2_zmb {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap; }
  .styles_container__2_zmb.styles_isReversed__2aSaH {
    flex-direction: row-reverse; }
  @media (min-width: 1200px) {
    .styles_container__2_zmb.styles_overlap__2uwr3 {
      margin-top: -12rem; } }
  .styles_container__2_zmb.styles_centeredText__29Dpb {
    align-items: center;
    justify-content: center; }
  @media (max-width: 1199px) {
    .styles_container__2_zmb {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap; }
      .styles_container__2_zmb.styles_centeredText__29Dpb {
        align-items: flex-start; }
      .styles_container__2_zmb.styles_isReversed__2aSaH {
        flex-direction: column-reverse;
        align-items: flex-end; } }
  .styles_container__2_zmb .styles_textContainer__1vXeV {
    align-items: flex-start;
    padding: 0 8rem; }
    @media (min-width: 1200px) {
      .styles_container__2_zmb .styles_textContainer__1vXeV.styles_overlap__2uwr3 {
        padding: 0 8rem;
        margin-top: -12rem; }
      .styles_container__2_zmb .styles_textContainer__1vXeV.styles_centeredText__29Dpb {
        justify-content: center;
        margin-bottom: 2rem; }
      .styles_container__2_zmb .styles_textContainer__1vXeV.styles_isReversed__2aSaH {
        margin-bottom: 0; }
      .styles_container__2_zmb .styles_textContainer__1vXeV.styles_isReversed__2aSaH .styles_container__2_zmb .styles_textContainer__1vXeV.styles_centeredText__29Dpb {
        margin-top: 12rem; } }
    @media (max-width: 1199px) {
      .styles_container__2_zmb .styles_textContainer__1vXeV {
        padding: 4rem 2rem; } }

.styles_header__2Xe4k {
  height: 80vh;
  background: url(/static/media/innit-bakgrunn-omselskapet.dab3963e.jpg) center center no-repeat;
  background-size: cover;
  background-color: #FFFFFF;
  color: #FFFFFF; }
  .styles_header__2Xe4k.styles_tree-by-water__1E90L {
    background: url(/static/media/innit-tre2.727d1261.jpg) center center no-repeat;
    background-size: cover; }
  .styles_header__2Xe4k.styles_rusty-building__3xIgf {
    background: url(/static/media/innit-bakgrunn-omselskapet.dab3963e.jpg) center center no-repeat;
    background-size: cover; }
  .styles_header__2Xe4k .styles_innerContainer___OT6h {
    margin: 0 auto;
    max-width: 1200px;
    padding-left: 8rem;
    padding-right: 8rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    height: 100%; }
    @media (max-width: 599px) {
      .styles_header__2Xe4k .styles_innerContainer___OT6h {
        padding-left: 2rem;
        padding-right: 2rem; } }
    @media (max-width: 1023px) {
      .styles_header__2Xe4k .styles_innerContainer___OT6h {
        padding-left: 4rem;
        padding-right: 4rem; } }
    .styles_header__2Xe4k .styles_innerContainer___OT6h .styles_textContainer__31ASh {
      min-height: 17.1875rem;
      background-color: #172727;
      padding: 3rem; }
      @media (max-width: 1023px) {
        .styles_header__2Xe4k .styles_innerContainer___OT6h .styles_textContainer__31ASh {
          padding: 2rem; } }
      .styles_header__2Xe4k .styles_innerContainer___OT6h .styles_textContainer__31ASh .styles_title___aZN0 {
        font-size: 1.75rem;
        font-family: objektiv-mk3, sans-serif;
        font-weight: 300;
        font-style: normal;
        text-transform: uppercase;
        margin: 0; }
        @media (max-width: 1023px) {
          .styles_header__2Xe4k .styles_innerContainer___OT6h .styles_textContainer__31ASh .styles_title___aZN0 {
            font-size: 1rem; } }
      .styles_header__2Xe4k .styles_innerContainer___OT6h .styles_textContainer__31ASh .styles_description__V7rzG {
        font-size: 2.625rem;
        font-family: objektiv-mk3, sans-serif;
        font-weight: 500;
        font-style: normal; }
        @media (max-width: 1023px) {
          .styles_header__2Xe4k .styles_innerContainer___OT6h .styles_textContainer__31ASh .styles_description__V7rzG {
            font-size: 1.75rem; } }
        @media (max-width: 599px) {
          .styles_header__2Xe4k .styles_innerContainer___OT6h .styles_textContainer__31ASh .styles_description__V7rzG {
            font-size: 1.25rem; } }
  .styles_header__2Xe4k .styles_arrowContainer__35Ej_ {
    margin: 0 auto;
    max-width: 1200px;
    padding-left: 8rem;
    padding-right: 8rem;
    position: relative; }
    @media (max-width: 599px) {
      .styles_header__2Xe4k .styles_arrowContainer__35Ej_ {
        padding-left: 2rem;
        padding-right: 2rem; } }
    @media (max-width: 1023px) {
      .styles_header__2Xe4k .styles_arrowContainer__35Ej_ {
        padding-left: 4rem;
        padding-right: 4rem; } }
    .styles_header__2Xe4k .styles_arrowContainer__35Ej_ .styles_arrow__1onfg {
      display: none;
      position: absolute;
      bottom: -8vh; }
      .styles_header__2Xe4k .styles_arrowContainer__35Ej_ .styles_arrow__1onfg.styles_innit-blood__21RV5 {
        display: block;
        fill: #73120d; }
      .styles_header__2Xe4k .styles_arrowContainer__35Ej_ .styles_arrow__1onfg.styles_innit-red__2ORTY {
        display: block;
        fill: #ff000a; }

@media (min-width: 1024px) {
  .styles_container__3KeYX {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    align-items: stretch; }
    .styles_container__3KeYX .styles_imageContainer__3CKIE {
      flex: 1 1;
      position: relative;
      height: inherit; }
      .styles_container__3KeYX .styles_imageContainer__3CKIE .styles_image__3_5IL {
        width: 100%;
        object-fit: cover;
        display: block;
        height: 100%; }
      .styles_container__3KeYX .styles_imageContainer__3CKIE .styles_captionContainer__1PWRN {
        font-family: objektiv-mk3, sans-serif;
        font-weight: 200;
        font-style: normal;
        font-size: 0.875rem;
        position: absolute;
        box-sizing: border-box;
        padding: 0.625rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #172727;
        left: 1rem;
        bottom: 1rem;
        opacity: 0; } }
      @media (min-width: 1024px) and (max-width: 1023px) {
        .styles_container__3KeYX .styles_imageContainer__3CKIE .styles_captionContainer__1PWRN {
          font-size: 0.75rem; } }
      @media (min-width: 1024px) and (max-width: 1023px) {
        .styles_container__3KeYX .styles_imageContainer__3CKIE .styles_captionContainer__1PWRN {
          opacity: 1; } }

@media (min-width: 1024px) {
        .styles_container__3KeYX .styles_imageContainer__3CKIE .styles_captionContainer__1PWRN .styles_figurecaption__2l9vM {
          color: white;
          margin: 0; }
    .styles_container__3KeYX .styles_imageContainer__3CKIE:hover .styles_captionContainer__1PWRN {
      -webkit-transition: opacity 1s ease;
      transition: opacity 1s ease;
      opacity: 1; }
    .styles_container__3KeYX .styles_textContainer__acMNp {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;
      flex: 1 0; }
      .styles_container__3KeYX .styles_textContainer__acMNp.styles_centeredText__2mB_u {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap; }
      .styles_container__3KeYX .styles_textContainer__acMNp .styles_textWrapper__wH7ri {
        padding-left: 4rem;
        padding-right: 4rem;
        padding-top: 4rem;
        padding-bottom: 4rem; }
      .styles_container__3KeYX .styles_textContainer__acMNp .styles_logoContainer__1ReYW {
        display: none; }
        .styles_container__3KeYX .styles_textContainer__acMNp .styles_logoContainer__1ReYW.styles_hasLogo__2p4t- {
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: nowrap;
          width: 100%; }
          .styles_container__3KeYX .styles_textContainer__acMNp .styles_logoContainer__1ReYW.styles_hasLogo__2p4t- .styles_logo__36wC7 {
            padding: 2rem 4.5rem 0 0; }
  .styles_container__3KeYX.styles_isReversed__3ESnA {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap; }
    .styles_container__3KeYX.styles_isReversed__3ESnA .styles_imageContainer__3CKIE {
      height: inherit; }
    .styles_container__3KeYX.styles_isReversed__3ESnA .styles_textContainer__acMNp .styles_textWrapper__wH7ri {
      padding-left: 4rem;
      padding-right: 4rem;
      padding-top: 4rem;
      padding-bottom: 4rem; }
    .styles_container__3KeYX.styles_isReversed__3ESnA .styles_textContainer__acMNp .styles_logoContainer__1ReYW {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap; }
      .styles_container__3KeYX.styles_isReversed__3ESnA .styles_textContainer__acMNp .styles_logoContainer__1ReYW.styles_hasLogo__2p4t- {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap; }
        .styles_container__3KeYX.styles_isReversed__3ESnA .styles_textContainer__acMNp .styles_logoContainer__1ReYW.styles_hasLogo__2p4t- .styles_logo__36wC7 {
          padding: 2rem 0 0 4.5rem; } }

@media (max-width: 1023px) {
  .styles_container__3KeYX {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    align-items: stretch; }
    .styles_container__3KeYX .styles_imageContainer__3CKIE {
      width: 100%;
      position: relative;
      height: inherit; }
      .styles_container__3KeYX .styles_imageContainer__3CKIE .styles_image__3_5IL {
        width: 100%;
        object-fit: cover;
        display: block;
        height: 100%; }
      .styles_container__3KeYX .styles_imageContainer__3CKIE .styles_captionContainer__1PWRN {
        font-family: objektiv-mk3, sans-serif;
        font-weight: 200;
        font-style: normal;
        font-size: 0.875rem;
        position: absolute;
        box-sizing: border-box;
        padding: 0.625rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #172727;
        left: 1rem;
        bottom: 1rem;
        opacity: 0; } }
      @media (max-width: 1023px) and (max-width: 1023px) {
        .styles_container__3KeYX .styles_imageContainer__3CKIE .styles_captionContainer__1PWRN {
          font-size: 0.75rem; } }
      @media (max-width: 1023px) and (max-width: 1023px) {
        .styles_container__3KeYX .styles_imageContainer__3CKIE .styles_captionContainer__1PWRN {
          opacity: 1; } }

@media (max-width: 1023px) {
        .styles_container__3KeYX .styles_imageContainer__3CKIE .styles_captionContainer__1PWRN .styles_figurecaption__2l9vM {
          color: white;
          margin: 0; }
    .styles_container__3KeYX .styles_imageContainer__3CKIE:hover .styles_captionContainer__1PWRN {
      -webkit-transition: opacity 1s ease;
      transition: opacity 1s ease;
      opacity: 1; }
    .styles_container__3KeYX .styles_textContainer__acMNp {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: nowrap;
      width: 100%;
      padding-top: 2rem;
      box-sizing: border-box; }
      .styles_container__3KeYX .styles_textContainer__acMNp .styles_logoContainer__1ReYW {
        display: none; }
  .styles_container__3KeYX.styles_isReversed__3ESnA {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    align-items: stretch; }
    .styles_container__3KeYX.styles_isReversed__3ESnA .styles_textContainer__acMNp {
      padding-bottom: 2rem; } }

.styles_pageContainer__2iW7P {
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 8rem;
  padding-bottom: 8rem; }
  @media (max-width: 599px) {
    .styles_pageContainer__2iW7P {
      padding-left: 2rem;
      padding-right: 2rem; } }
  @media (max-width: 1023px) {
    .styles_pageContainer__2iW7P {
      padding-left: 0;
      padding-right: 0;
      padding-top: 4rem;
      padding-bottom: 4rem; } }
  .styles_pageContainer__2iW7P .styles_textContainer__1EhBx {
    padding-top: 4rem;
    padding-bottom: 4rem; }
    @media (max-width: 1023px) {
      .styles_pageContainer__2iW7P .styles_textContainer__1EhBx {
        padding: 4rem; } }
  .styles_pageContainer__2iW7P .styles_bannerContainer__GfTjt {
    padding-top: 4rem;
    padding-bottom: 4rem; }
    @media (max-width: 1023px) {
      .styles_pageContainer__2iW7P .styles_bannerContainer__GfTjt {
        padding-top: 2rem;
        padding-bottom: 2rem; } }
  .styles_pageContainer__2iW7P .styles_doubleImageContainer__13pMg {
    padding-top: 4rem;
    padding-bottom: 4rem; }
    @media (max-width: 1023px) {
      .styles_pageContainer__2iW7P .styles_doubleImageContainer__13pMg {
        padding: 0; } }
  .styles_pageContainer__2iW7P .styles_partnersContainer__D98dG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 12rem; }
    @media (max-width: 1023px) {
      .styles_pageContainer__2iW7P .styles_partnersContainer__D98dG {
        padding-top: 4rem; } }
    .styles_pageContainer__2iW7P .styles_partnersContainer__D98dG div {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 2.5rem;
      max-height: none; }
      @media (max-width: 1023px) {
        .styles_pageContainer__2iW7P .styles_partnersContainer__D98dG div {
          padding: 1rem; }
          .styles_pageContainer__2iW7P .styles_partnersContainer__D98dG div img {
            object-fit: contain; } }

.styles_header__1vsD0 {
  background-color: #172727;
  height: 80vh; }
  .styles_header__1vsD0 .styles_headerContainer__3qOld {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: inherit;
    flex-wrap: nowrap;
    background-color: #172727;
    height: 100%; }
    .styles_header__1vsD0 .styles_headerContainer__3qOld .styles_textContainer__sNlq6 {
      padding-left: 2rem;
      padding-right: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: nowrap;
      flex: 1 1;
      color: #FFFFFF; }
      @media (max-width: 599px) {
        .styles_header__1vsD0 .styles_headerContainer__3qOld .styles_textContainer__sNlq6 {
          justify-content: flex-end; } }
      .styles_header__1vsD0 .styles_headerContainer__3qOld .styles_textContainer__sNlq6 .styles_description__2YHJm {
        margin: 0;
        font-size: 3.5rem;
        font-family: objektiv-mk3, sans-serif;
        font-weight: 500;
        font-style: normal; }
        @media (max-width: 1023px) {
          .styles_header__1vsD0 .styles_headerContainer__3qOld .styles_textContainer__sNlq6 .styles_description__2YHJm {
            font-size: 2.625rem; } }
        @media (max-width: 599px) {
          .styles_header__1vsD0 .styles_headerContainer__3qOld .styles_textContainer__sNlq6 .styles_description__2YHJm {
            font-size: 1.5rem; } }
        .styles_header__1vsD0 .styles_headerContainer__3qOld .styles_textContainer__sNlq6 .styles_description__2YHJm .styles_textLoop__3L_DY {
          overflow: hidden;
          margin-bottom: 0.5rem; }
          .styles_header__1vsD0 .styles_headerContainer__3qOld .styles_textContainer__sNlq6 .styles_description__2YHJm .styles_textLoop__3L_DY .styles_textLoopText__10VGb {
            margin: 1rem 0 0 0; }
    .styles_header__1vsD0 .styles_headerContainer__3qOld .styles_csContainer__sIcxO {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      flex-wrap: nowrap;
      position: absolute;
      right: 0;
      height: 100%;
      flex: 1 1; }
      @media (max-width: 599px) {
        .styles_header__1vsD0 .styles_headerContainer__3qOld .styles_csContainer__sIcxO {
          position: relative; } }
      @media (min-width: 1200px) {
        .styles_header__1vsD0 .styles_headerContainer__3qOld .styles_csContainer__sIcxO {
          right: calc(50% - 600px); } }
      .styles_header__1vsD0 .styles_headerContainer__3qOld .styles_csContainer__sIcxO .styles_arrow__2HQyh {
        fill: #73120d;
        height: 15.625rem;
        width: 15.625rem;
        -webkit-transform: rotateZ(90deg);
                transform: rotateZ(90deg); }
        @media (max-width: 599px) {
          .styles_header__1vsD0 .styles_headerContainer__3qOld .styles_csContainer__sIcxO .styles_arrow__2HQyh {
            height: 9.375rem;
            width: 9.375rem; } }
      .styles_header__1vsD0 .styles_headerContainer__3qOld .styles_csContainer__sIcxO .styles_csblock__36IB4 {
        background-color: #ff000a;
        padding: 2rem;
        border: none;
        text-decoration: none; }
        .styles_header__1vsD0 .styles_headerContainer__3qOld .styles_csContainer__sIcxO .styles_csblock__36IB4:hover {
          background-color: #73120d; }
        .styles_header__1vsD0 .styles_headerContainer__3qOld .styles_csContainer__sIcxO .styles_csblock__36IB4 .styles_csText__FIJNA {
          color: #FFFFFF;
          margin: 0;
          text-decoration: underline;
          font-size: 1.75rem;
          font-family: objektiv-mk3, sans-serif;
          font-weight: 400;
          font-style: normal; }
          @media (max-width: 1023px) {
            .styles_header__1vsD0 .styles_headerContainer__3qOld .styles_csContainer__sIcxO .styles_csblock__36IB4 .styles_csText__FIJNA {
              font-size: 1rem; } }

.styles_container__oJOVD {
  margin: 0 auto;
  max-width: 1200px; }
  .styles_container__oJOVD .styles_textWrapper__3r_6K {
    padding-top: 4rem; }
    @media (max-width: 1023px) {
      .styles_container__oJOVD .styles_textWrapper__3r_6K {
        padding: 4rem 2rem 0 2rem; } }
  .styles_container__oJOVD .styles_random__346li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 8rem; }
    @media (max-width: 599px) {
      .styles_container__oJOVD .styles_random__346li {
        padding-top: 4rem; } }
    @media (min-width: 600px) {
      .styles_container__oJOVD .styles_random__346li {
        padding-bottom: 4rem; } }
    .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(8, 1fr); }
      @media (max-width: 599px) {
        .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y {
          grid-template-rows: repeat(6, 1fr); } }
      .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_img1__n5tOd {
        grid-area: 1 / 1 / 5 / 3; }
        @media (max-width: 599px) {
          .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_img1__n5tOd {
            grid-area: 1 / 1 / 3 / 3; } }
      .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_img2__SlQIa {
        grid-area: 5 / 2 / 9 / 4; }
        @media (max-width: 1023px) {
          .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_img2__SlQIa {
            grid-area: 5 / 1 / 9 / 4;
            padding-left: 4rem; } }
        @media (max-width: 599px) {
          .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_img2__SlQIa {
            grid-area: 5 / 1 / 3 / 4; } }
      .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_img3__2F8Ek {
        grid-area: 2 / 3 / 7 / 5; }
        @media (max-width: 599px) {
          .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_img3__2F8Ek {
            grid-area: 2 / 3 / 4 / 5; } }
      .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_text__nQaPG {
        grid-area: 7 / 5 / 9 / 7;
        font-family: objektiv-mk3, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 1.75rem;
        padding-right: 2rem; }
        @media (max-width: 599px) {
          .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_text__nQaPG {
            grid-area: 5 / 1 / 7 / 7;
            padding: 4rem 2rem; } }
        @media (max-width: 1023px) {
          .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_text__nQaPG {
            font-size: 1rem; } }
      .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_img__3CYyu {
        max-width: 25rem;
        object-fit: contain; }
        @media (max-width: 599px) {
          .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_img__3CYyu {
            max-width: 12.5rem;
            height: inherit;
            width: inherit; } }
      .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_arrow__19SLl {
        padding-top: 2rem;
        padding-bottom: 2rem;
        fill: #ff000a;
        width: 12.5rem;
        height: 12.5rem; }
        @media (max-width: 1023px) {
          .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_arrow__19SLl {
            display: none; } }
      .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_arr1__33VaO {
        grid-area: 1 / 6 / 3 / 7; }
      .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_arr2__3-tio {
        grid-area: 3 / 6 / 5 / 7; }
      .styles_container__oJOVD .styles_random__346li .styles_imageCollection__3AJ8Y .styles_arr3__DaLb6 {
        grid-area: 5 / 6 / 7 / 7; }

.styles_container__2LaFH {
  height: 70vh;
  background-color: #172727; }
  .styles_container__2LaFH + * {
    display: none; }
  @media (max-width: 1023px) {
    .styles_container__2LaFH {
      height: 100%; } }
  .styles_container__2LaFH .styles_textWrapper__1idWj {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; }
    @media (max-width: 1023px) {
      .styles_container__2LaFH .styles_textWrapper__1idWj {
        flex-direction: column;
        padding-top: 8rem; } }
    .styles_container__2LaFH .styles_textWrapper__1idWj .styles_textContainer__1dHDI {
      font-family: objektiv-mk3, sans-serif;
      font-weight: 200;
      font-style: normal;
      font-size: 1.5rem;
      color: white; }
      @media (max-width: 1023px) {
        .styles_container__2LaFH .styles_textWrapper__1idWj .styles_textContainer__1dHDI {
          font-size: 1.25rem; } }
      @media (max-width: 599px) {
        .styles_container__2LaFH .styles_textWrapper__1idWj .styles_textContainer__1dHDI {
          font-size: 1rem; } }
      .styles_container__2LaFH .styles_textWrapper__1idWj .styles_textContainer__1dHDI .styles_title__2P-JU {
        font-family: objektiv-mk3, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 1.125rem;
        padding-bottom: 1rem; }
        @media (max-width: 1023px) {
          .styles_container__2LaFH .styles_textWrapper__1idWj .styles_textContainer__1dHDI .styles_title__2P-JU {
            font-size: 1rem; } }
      .styles_container__2LaFH .styles_textWrapper__1idWj .styles_textContainer__1dHDI .styles_text__xD6lL {
        margin: 0;
        padding-top: 0.5rem; }
      .styles_container__2LaFH .styles_textWrapper__1idWj .styles_textContainer__1dHDI .styles_linkContainer__3BOVZ {
        padding-top: 4rem; }
        .styles_container__2LaFH .styles_textWrapper__1idWj .styles_textContainer__1dHDI .styles_linkContainer__3BOVZ .styles_link__3QJ0c {
          color: white; }
    .styles_container__2LaFH .styles_textWrapper__1idWj .styles_imgContainer__CBnHu {
      padding-left: 8vh;
      padding-top: 7vh; }
      @media (max-width: 1023px) {
        .styles_container__2LaFH .styles_textWrapper__1idWj .styles_imgContainer__CBnHu {
          padding-top: 9vh;
          padding-left: 0; } }

.styles_header__x3-yC {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 80vh;
  background-color: #FFFFFF;
  color: #FFFFFF;
  overflow: hidden; }
  @media (max-width: 599px) {
    .styles_header__x3-yC {
      grid-template-rows: 70vh; } }
  .styles_header__x3-yC .styles_headerImgContainer__2ytIi {
    grid-column: 1 / 6;
    grid-row: 1 / 1;
    height: 100%; }
    .styles_header__x3-yC .styles_headerImgContainer__2ytIi .styles_headerImg__1qFvD {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center top; }
  .styles_header__x3-yC .styles_headerTextContainer__3BkmR {
    grid-column: 4 / 6;
    grid-row: 1 / 1;
    min-height: 30vh;
    background-color: #172727;
    align-self: end;
    padding: 2rem; }
    @media (max-width: 599px) {
      .styles_header__x3-yC .styles_headerTextContainer__3BkmR {
        grid-column: 3 / 6;
        min-height: 10vh; } }
    @media (max-width: 599px) {
      .styles_header__x3-yC .styles_headerTextContainer__3BkmR .styles_title__2yR77 {
        font-size: 0.75rem; } }
    .styles_header__x3-yC .styles_headerTextContainer__3BkmR .styles_descriptionContainer__3Fgmk .styles_description__e55h3 {
      text-align: start;
      font-size: 2rem;
      margin: 0; }
      @media (max-width: 599px) {
        .styles_header__x3-yC .styles_headerTextContainer__3BkmR .styles_descriptionContainer__3Fgmk .styles_description__e55h3 {
          font-size: 1.125rem; } }

.styles_tagsWrapper__2jsZg {
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: 2rem;
  padding-right: 2rem; }
  .styles_tagsWrapper__2jsZg .styles_ourPeople__35Iw_ {
    font-family: objektiv-mk3, sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 3rem; }
    @media (max-width: 1023px) {
      .styles_tagsWrapper__2jsZg .styles_ourPeople__35Iw_ {
        font-size: 1.375rem; } }
  .styles_tagsWrapper__2jsZg .styles_tags__B5mP1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-bottom: 1rem; }
    .styles_tagsWrapper__2jsZg .styles_tags__B5mP1 .styles_tagWrapper__3g103 {
      margin-right: 2rem; }

.styles_employees__3UeT4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1200px; }
  @media (max-width: 1199px) {
    .styles_employees__3UeT4 {
      padding-left: 1.5rem; } }

.styles_contentWrapper__3YdyN {
  padding-top: 8rem;
  padding-bottom: 8rem;
  margin: 0 auto;
  max-width: 1200px; }

.styles_header__1pIau {
  background-color: #172727; }
  .styles_header__1pIau .styles_headerTextWrapper__1FBij {
    margin: 0 auto;
    max-width: 1200px;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: #172727;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    color: #FFFFFF; }
    .styles_header__1pIau .styles_headerTextWrapper__1FBij .styles_title__2M1KL {
      font-size: 1.75rem;
      font-family: objektiv-mk3, sans-serif;
      font-weight: 300;
      font-style: normal;
      margin: 0; }
      @media (max-width: 1023px) {
        .styles_header__1pIau .styles_headerTextWrapper__1FBij .styles_title__2M1KL {
          font-size: 1rem; } }
    .styles_header__1pIau .styles_headerTextWrapper__1FBij .styles_description__1ZHaW {
      font-size: 2.625rem;
      font-family: objektiv-mk3, sans-serif;
      font-weight: 500;
      font-style: normal; }
      @media (max-width: 1023px) {
        .styles_header__1pIau .styles_headerTextWrapper__1FBij .styles_description__1ZHaW {
          font-size: 1.75rem; } }
      @media (max-width: 599px) {
        .styles_header__1pIau .styles_headerTextWrapper__1FBij .styles_description__1ZHaW {
          font-size: 1.25rem; } }
  .styles_header__1pIau .styles_arrowContainer__YAwjz {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    position: absolute;
    bottom: 12vh;
    left: 0;
    right: 0; }
    .styles_header__1pIau .styles_arrowContainer__YAwjz .styles_arrow__CoV2F {
      width: 20vh;
      height: 20vh;
      fill: #ff000a;
      -webkit-transform: rotateZ(90deg);
              transform: rotateZ(90deg); }
      @media (max-width: 599px) {
        .styles_header__1pIau .styles_arrowContainer__YAwjz .styles_arrow__CoV2F {
          width: 12vh;
          height: 12vh; } }
  .styles_header__1pIau .styles_iceArea__3tC1R {
    height: 12.5rem;
    background-color: #d7e3f1; }
    @media (max-width: 1023px) {
      .styles_header__1pIau .styles_iceArea__3tC1R {
        height: 6.25rem; } }

.styles_container__-F_A- {
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 8rem;
  padding-right: 8rem; }
  @media (max-width: 599px) {
    .styles_container__-F_A- {
      padding-left: 2rem;
      padding-right: 2rem; } }

.styles_header__1O-rI {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  background-color: #172727;
  height: 80vh;
  width: 100%; }
  @media (max-width: 1023px) {
    .styles_header__1O-rI {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: nowrap; } }
  .styles_header__1O-rI .styles_headerImgContainer__2WVWL {
    flex: 1 0 50%;
    height: 100%;
    position: relative; }
    @media (max-width: 1023px) {
      .styles_header__1O-rI .styles_headerImgContainer__2WVWL {
        width: 100%;
        max-height: 40vh; } }
    .styles_header__1O-rI .styles_headerImgContainer__2WVWL .styles_HeaderImg__3e9v_ {
      height: inherit;
      width: 100%;
      object-fit: cover; }
    .styles_header__1O-rI .styles_headerImgContainer__2WVWL .styles_captionContainer__RLIdK {
      font-family: objektiv-mk3, sans-serif;
      font-weight: 200;
      font-style: normal;
      font-size: 0.875rem;
      position: absolute;
      box-sizing: border-box;
      padding: 0.625rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: #172727;
      left: 1rem;
      bottom: 1rem;
      opacity: 0; }
      @media (max-width: 1023px) {
        .styles_header__1O-rI .styles_headerImgContainer__2WVWL .styles_captionContainer__RLIdK {
          font-size: 0.75rem; } }
      @media (max-width: 1023px) {
        .styles_header__1O-rI .styles_headerImgContainer__2WVWL .styles_captionContainer__RLIdK {
          opacity: 1; } }
      .styles_header__1O-rI .styles_headerImgContainer__2WVWL .styles_captionContainer__RLIdK .styles_figurecaption__27RMD {
        color: white;
        margin: 0; }
  .styles_header__1O-rI .styles_headerImgContainer__2WVWL:hover .styles_captionContainer__RLIdK {
    -webkit-transition: opacity 1s ease;
    transition: opacity 1s ease;
    opacity: 1; }
  .styles_header__1O-rI .styles_headerTextContainer__2v-OM {
    flex: 1 0 50%;
    color: white; }
    .styles_header__1O-rI .styles_headerTextContainer__2v-OM .styles_textWrapper__2BCrm {
      padding: 8rem;
      max-width: 29.5rem; }
      @media (max-width: 1199px) {
        .styles_header__1O-rI .styles_headerTextContainer__2v-OM .styles_textWrapper__2BCrm {
          padding: 3rem; } }
      @media (max-width: 1023px) {
        .styles_header__1O-rI .styles_headerTextContainer__2v-OM .styles_textWrapper__2BCrm {
          padding-top: 4.5rem;
          padding-bottom: 4.5rem; } }
      @media (max-width: 599px) {
        .styles_header__1O-rI .styles_headerTextContainer__2v-OM .styles_textWrapper__2BCrm {
          padding: 2rem; } }
      .styles_header__1O-rI .styles_headerTextContainer__2v-OM .styles_textWrapper__2BCrm .styles_title__2DXg- {
        font-size: 1.75rem;
        font-family: objektiv-mk3, sans-serif;
        font-weight: 300;
        font-style: normal;
        text-transform: uppercase; }
        @media (max-width: 1023px) {
          .styles_header__1O-rI .styles_headerTextContainer__2v-OM .styles_textWrapper__2BCrm .styles_title__2DXg- {
            font-size: 1rem; } }
      .styles_header__1O-rI .styles_headerTextContainer__2v-OM .styles_textWrapper__2BCrm .styles_customerText__12ec- {
        font-size: 2.625rem; }
        @media (max-width: 1023px) {
          .styles_header__1O-rI .styles_headerTextContainer__2v-OM .styles_textWrapper__2BCrm .styles_customerText__12ec- {
            font-size: 1.75rem; } }
        @media (max-width: 599px) {
          .styles_header__1O-rI .styles_headerTextContainer__2v-OM .styles_textWrapper__2BCrm .styles_customerText__12ec- {
            font-size: 1.25rem; } }

.styles_container__1Z3i3 {
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 8rem;
  padding-right: 8rem; }
  @media (max-width: 599px) {
    .styles_container__1Z3i3 {
      padding-left: 2rem;
      padding-right: 2rem; } }
  .styles_container__1Z3i3 .styles_arrowWrapper__3Cnsb {
    display: flex;
    justify-content: flex-end; }
    .styles_container__1Z3i3 .styles_arrowWrapper__3Cnsb .styles_arrowContainer__2FUFC {
      width: 50%;
      height: 100%;
      position: relative; }
      .styles_container__1Z3i3 .styles_arrowWrapper__3Cnsb .styles_arrowContainer__2FUFC .styles_arrow__3rahA {
        fill: red;
        height: 20vh;
        width: 20vh;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        position: absolute;
        z-index: 2;
        bottom: -8vh;
        right: 0; }

.styles_container__2heYp {
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 0;
  padding-bottom: 6rem; }
  @media (max-width: 599px) {
    .styles_container__2heYp {
      padding-left: 2rem;
      padding-right: 2rem; } }
  @media (max-width: 599px) {
    .styles_container__2heYp {
      padding-bottom: 3rem; } }
  .styles_container__2heYp .styles_blockContent__3US-i, .styles_container__2heYp .styles_blockContent_statement__1o02d {
    margin: 0;
    padding-left: 4rem;
    padding-right: 4rem;
    font-family: objektiv-mk3, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 1.5rem; }
    @media (max-width: 1023px) {
      .styles_container__2heYp .styles_blockContent__3US-i, .styles_container__2heYp .styles_blockContent_statement__1o02d {
        font-size: 1.25rem; } }
    @media (max-width: 599px) {
      .styles_container__2heYp .styles_blockContent__3US-i, .styles_container__2heYp .styles_blockContent_statement__1o02d {
        font-size: 1rem; } }
    @media (max-width: 1023px) {
      .styles_container__2heYp .styles_blockContent__3US-i, .styles_container__2heYp .styles_blockContent_statement__1o02d {
        padding-left: 0;
        padding-right: 0; } }
  .styles_container__2heYp .styles_videoFrame__oe7pm {
    width: 100%;
    height: 34rem;
    border: 0;
    padding-top: 6rem; }
    @media (max-width: 599px) {
      .styles_container__2heYp .styles_videoFrame__oe7pm {
        height: 16rem;
        padding-top: 1rem; } }
  .styles_container__2heYp .styles_partnersContainer__c-pYO {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 6rem; }
    @media (max-width: 1023px) {
      .styles_container__2heYp .styles_partnersContainer__c-pYO {
        padding-top: 4rem; } }
    .styles_container__2heYp .styles_partnersContainer__c-pYO div {
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 2.5rem;
      max-height: none; }
      @media (max-width: 1023px) {
        .styles_container__2heYp .styles_partnersContainer__c-pYO div {
          padding: 1rem; }
          .styles_container__2heYp .styles_partnersContainer__c-pYO div img {
            object-fit: contain; } }

.styles_header__1UtMp {
  background-color: #172727; }
  .styles_header__1UtMp .styles_headerTextWrapper__3VsHk {
    margin: 0 auto;
    max-width: 1200px;
    padding-left: 2rem;
    padding-right: 2rem;
    background-color: #172727;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    color: #FFFFFF; }
    .styles_header__1UtMp .styles_headerTextWrapper__3VsHk .styles_title__uGv8j {
      font-size: 1.75rem;
      font-family: objektiv-mk3, sans-serif;
      font-weight: 300;
      font-style: normal;
      margin: 0; }
      @media (max-width: 1023px) {
        .styles_header__1UtMp .styles_headerTextWrapper__3VsHk .styles_title__uGv8j {
          font-size: 1rem; } }
    .styles_header__1UtMp .styles_headerTextWrapper__3VsHk .styles_description__3Ed05 {
      font-size: 2.625rem;
      font-family: objektiv-mk3, sans-serif;
      font-weight: 500;
      font-style: normal; }
      @media (max-width: 1023px) {
        .styles_header__1UtMp .styles_headerTextWrapper__3VsHk .styles_description__3Ed05 {
          font-size: 1.75rem; } }
      @media (max-width: 599px) {
        .styles_header__1UtMp .styles_headerTextWrapper__3VsHk .styles_description__3Ed05 {
          font-size: 1.25rem; } }

.styles_pageContainer__2HDYg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding-left: 8rem;
  padding-right: 8rem;
  margin: 0 auto;
  max-width: 1200px; }
  @media (max-width: 599px) {
    .styles_pageContainer__2HDYg {
      padding-left: 2rem;
      padding-right: 2rem; } }
  .styles_pageContainer__2HDYg .styles_partnersContainer__3yyA- {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 12rem; }
    @media (max-width: 1023px) {
      .styles_pageContainer__2HDYg .styles_partnersContainer__3yyA- {
        padding-top: 4rem; } }

.styles_pageContainer__1j28j {
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 0;
  padding-bottom: 8rem; }
  @media (max-width: 599px) {
    .styles_pageContainer__1j28j {
      padding-left: 2rem;
      padding-right: 2rem; } }
  @media (max-width: 1023px) {
    .styles_pageContainer__1j28j {
      padding-left: 0;
      padding-right: 0; } }

.styles_sectionContainer__2aiwE {
  min-height: 0;
  padding-top: 4rem;
  padding-bottom: 0; }

.styles_pageContainer__1q6fr {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #172727;
  color: #FFFFFF;
  height: 100vh; }
  .styles_pageContainer__1q6fr + * {
    display: none; }

.styles_pageContent__1Af1M {
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 8rem;
  padding-bottom: 8rem; }
  @media (max-width: 599px) {
    .styles_pageContent__1Af1M {
      padding-left: 2rem;
      padding-right: 2rem; } }
  @media (max-width: 1023px) {
    .styles_pageContent__1Af1M {
      padding: 4rem; } }
  .styles_pageContent__1Af1M p {
    line-height: 2;
    padding-top: 2rem; }

.styles_pageContainer__2ozMv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #172727;
  color: #FFFFFF; }
  .styles_pageContainer__2ozMv + * {
    display: none; }

.styles_pageContent__1RWvy {
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 8rem;
  padding-bottom: 8rem; }
  @media (max-width: 599px) {
    .styles_pageContent__1RWvy {
      padding-left: 2rem;
      padding-right: 2rem; } }
  @media (max-width: 1023px) {
    .styles_pageContent__1RWvy {
      padding: 4rem; } }
  .styles_pageContent__1RWvy p {
    line-height: 2;
    padding-bottom: 2rem; }
    @media (max-width: 1023px) {
      .styles_pageContent__1RWvy p {
        padding-bottom: 1rem; } }
  .styles_pageContent__1RWvy a {
    color: white !important; }
    .styles_pageContent__1RWvy a:hover {
      color: #ff000a !important; }

.styles_container__2KCLu {
  height: 100%;
  background-color: #172727; }
  .styles_container__2KCLu + * {
    display: none; }
  @media (max-width: 1023px) {
    .styles_container__2KCLu {
      height: 100%; } }
  .styles_container__2KCLu .styles_textWrapper__dO6Kw {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    padding-top: 8rem;
    padding-bottom: 8rem;
    height: 100%; }
    @media (max-width: 1023px) {
      .styles_container__2KCLu .styles_textWrapper__dO6Kw {
        flex-direction: column;
        padding-top: 8rem; } }
    .styles_container__2KCLu .styles_textWrapper__dO6Kw .styles_textContainer__3qFMc {
      font-family: objektiv-mk3, sans-serif;
      font-weight: 200;
      font-style: normal;
      font-size: 1.5rem;
      color: white; }
      @media (max-width: 1023px) {
        .styles_container__2KCLu .styles_textWrapper__dO6Kw .styles_textContainer__3qFMc {
          font-size: 1.25rem; } }
      @media (max-width: 599px) {
        .styles_container__2KCLu .styles_textWrapper__dO6Kw .styles_textContainer__3qFMc {
          font-size: 1rem; } }
    .styles_container__2KCLu .styles_textWrapper__dO6Kw .styles_links__GrSoB {
      color: black; }

.styles_pageContainer__2ZAyN {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #172727;
  color: #FFFFFF; }
  .styles_pageContainer__2ZAyN + * {
    display: none; }

.styles_pageContent__2wOVn {
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 8rem;
  padding-right: 8rem;
  padding-top: 8rem;
  padding-bottom: 8rem; }
  @media (max-width: 599px) {
    .styles_pageContent__2wOVn {
      padding-left: 2rem;
      padding-right: 2rem; } }
  @media (max-width: 1023px) {
    .styles_pageContent__2wOVn {
      padding: 4rem; } }
  .styles_pageContent__2wOVn p {
    word-break: break-word;
    line-height: 2;
    padding-bottom: 2rem; }
    @media (max-width: 1023px) {
      .styles_pageContent__2wOVn p {
        padding-bottom: 1rem; } }
  .styles_pageContent__2wOVn a {
    color: white !important; }
    .styles_pageContent__2wOVn a:hover {
      color: #ff000a !important; }

.styles_step__2UF6Y {
  padding-top: 4rem; }
  @media (max-width: 599px) {
    .styles_step__2UF6Y {
      padding-top: 2rem; } }
  .styles_step__2UF6Y > div {
    padding-top: 0; }

.styles_videoFrame__Rvdvo {
  width: 100%;
  height: 34rem;
  border: 0; }
  @media (max-width: 599px) {
    .styles_videoFrame__Rvdvo {
      height: 16rem; } }

.App_app__2ziFi {
  background-color: #FFFFFF; }

.App_footerContainer__zlfyd {
  background-color: #172727; }

