@import './rem';

// Header Size
$header-height-pc: 80vh;
$header-height-mobile: 70vh;

$size-pad: rem(8px);

/////////
// Standard padding and margin
/////////
$pad: $size-pad; // ~ 8 px
$pad-2: $pad * 0.25; // ~ 2 px
$pad-4: $pad * 0.5; // ~ 4 px
$pad-8: $pad * 1; // ~ 8 px
$pad-12: $pad * 1.5; // ~ 12 px
$pad-16: $pad * 2; // ~ 16 px
$pad-24: $pad * 3; // ~ 24 px
$pad-32: $pad * 4; // ~ 32 px
$pad-40: $pad * 5; // ~ 40 px
$pad-48: $pad * 6; // ~ 48 px
$pad-56: $pad * 7; // ~ 64 px
$pad-64: $pad * 8; // ~ 64 px
$pad-72: $pad * 9; // ~ 72 px
$pad-80: $pad * 10; // ~ 80 px
$pad-96: $pad * 12; // ~ 80 px
$pad-128: $pad * 16;
$pad-192: $pad * 24;
$pad-256: $pad * 32;

$normal-lineHeight: rem(24px);
