@import url("https://use.typekit.net/njb2mcb.css");

@mixin objektiv($weight, $style: normal) {
  // style = normal, italic, bold
  font-family: objektiv-mk3, sans-serif;
  @include font-weight($weight);
  font-style: $style;
}

@mixin font-weight($weight) {
  $weights: (
    thin: 200,
    light: 300,
    normal: 400,
    regular: 400,
    medium: 500,
    bold: 700,
  );

  $output: $weight;
  @if map-has-key($weights, $weight) {
    $output: map-get($weights, $weight);
  }

  font-weight: $output;
}
