@import 'styles/sharedStyles';

.pageContainer {
  @include flex(row, center, center, wrap);
  background-color: $innit-wood;
  color: $white;

  // Footer override
  & + * {
    display: none;
  }
}

.pageContent {
  @include containerWrapper();
  @include normal-padding ();
  @include vertical-padding($pad-128);
  @include for-tablet-landscape-down {
    padding: $pad-64;
  }

  // TextWithLink override
  p {
    word-break: break-word;
    line-height: 2;
    padding-bottom: $pad-32;
    @include for-tablet-landscape-down {
      padding-bottom: $pad-16;
    }
  }

  // Sanity block override
  a {
    color: white !important;
    &:hover {
      color: $innit-red !important;
    }
  }
}

.step {
  padding-top: $pad-64;
  @include for-phone-only {
    padding-top: $pad-32;
  }
  > div {
    padding-top: 0;
  }
}

.videoFrame {
  width: 100%;
  height: 34rem;
  border: 0;
  @include for-phone-only {
    height: 16rem;
  }
}
