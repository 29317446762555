@import 'styles/sharedStyles.scss';

html {
  overflow: auto;
}

body {
  margin: 0;
  font-size: 16px;
  @include objektiv(regular, normal);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
