@mixin flex($direction: row, $justify-content: initial, $align-items: initial, $flex-wrap: nowrap, $display: flex) {
  display: $display;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-wrap: $flex-wrap;
}

@mixin inline-flex($direction: row, $justify-content: initial, $align-items: initial, $flex-wrap: nowrap) {
  @include flex($direction, $justify-content, $align-items, $flex-wrap, inline-flex);
}

@mixin normal-padding () {
  padding-left: $pad-128;
  padding-right: $pad-128;
  @include for-phone-only {
    padding-left: $pad-32;
    padding-right: $pad-32;
  }
}

@mixin vertical-padding ($paddingTop, $paddingBottom: $paddingTop) {
  padding-top: $paddingTop;
  padding-bottom: $paddingBottom;
}

@mixin horizontal-padding ($paddingLeft, $paddingRight: $paddingLeft) {
  padding-left: $paddingLeft;
  padding-right: $paddingRight;
}

@mixin containerWrapper {
  margin: 0 auto;
  max-width: 1200px;
}

@mixin bigContainerWrapper {
  margin: 0 auto;
  max-width: 1500px;
}

// Media Queries

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-down {
  @media (max-width: 1023px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 1024px) { @content; }
}
@mixin for-desktop-down {
  @media (max-width: 1199px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

// font-size

@mixin xxl-font() {
  font-size: rem(56px);
  @include for-tablet-landscape-down {
    font-size: rem(42px);
  }
  @include for-phone-only {
    font-size: rem(24px);
  }
}

@mixin xl-font() {
  font-size: rem(48px);
  @include for-tablet-landscape-down {
    font-size: rem(22px);
  }
}

@mixin md-font() {
  font-size: rem(42px);
  @include for-tablet-landscape-down {
    font-size: rem(28px);
  }
  @include for-phone-only {
    font-size: rem(20px);
  }
}

@mixin sm-font() {
  font-size: rem(28px);
  @include for-tablet-landscape-down {
    font-size: rem(16px);
  }
}

@mixin xs-font() {
  font-size: rem(24px);
  @include for-tablet-landscape-down {
    font-size: rem(20px);
  }
  @include for-phone-only {
    font-size: rem(16px);
  }
}

@mixin xxs-font() {
  font-size: rem(18px);
  @include for-tablet-landscape-down {
    font-size: rem(16px);
  }
}

@mixin xxxs-font() {
  font-size: rem(14px);
  @include for-tablet-landscape-down {
    font-size: rem(12px);
  }
}
